"use client";

import { isMac } from "lib/platform.utils";
import { Hotkey } from "react-hotkeys-hook/dist/types";

/* ------------------------------------------------------ */
/*                        Modifiers                       */
/* ------------------------------------------------------ */
export enum Modifier {
  command = "command",
  shift = "shift",
  alt = "alt",
}
const OrderedModifiers = [
  Modifier.command,
  Modifier.shift,
  Modifier.alt,
] as const;

enum RuntimeModifier {
  /** Modifier.command translates to `meta ⌘` on Mac */
  meta = "meta",
  /** Modifier.command translates to `ctrl ⌃` on Windows */
  ctrl = "ctrl",

  alt = "alt",
  shift = "shift",
}

/* ------------------------------------------------------ */
/*                           Map                          */
/* ------------------------------------------------------ */
export const Shortcut = {
  /** 🔥 CMDK 🔥 */
  [`${Modifier.command}+k`]: isMac()
    ? `${RuntimeModifier.meta}+k`
    : `${RuntimeModifier.ctrl}+k`,

  /** Open AI commands in focused Groove editor */
  [`${Modifier.command}+j`]: isMac()
    ? `${RuntimeModifier.meta}+j`
    : `${RuntimeModifier.ctrl}+j`,

  /** Open embedded copilot */
  [`${Modifier.command}+g`]: isMac()
    ? `${RuntimeModifier.meta}+g`
    : `${RuntimeModifier.ctrl}+g`,

  /** Full-unfull-screen embedded copilot */
  [`${Modifier.command}+f`]: isMac()
    ? `${RuntimeModifier.meta}+f`
    : `${RuntimeModifier.ctrl}+f`,

  /** Org Switcher */
  [`${Modifier.command}+o`]: isMac()
    ? `${RuntimeModifier.meta}+o`
    : `${RuntimeModifier.ctrl}+o`,

  "[": "[",

  /** Send chat reply or note */
  [`${Modifier.command}+enter`]: isMac()
    ? `${RuntimeModifier.meta}+enter`
    : `${RuntimeModifier.ctrl}+enter`,

  /** Open change session assignee menu */
  [`${Modifier.alt}+a`]: `${RuntimeModifier.alt}+a`,
  /** Open change session status menu */
  [`${Modifier.alt}+s`]: `${RuntimeModifier.alt}+s`,
  /** Change session status to resolved (close session) */
  [`${Modifier.alt}+e`]: `${RuntimeModifier.alt}+e`,
  /** Copy session ticket number */
  [`${Modifier.alt}+n`]: `${RuntimeModifier.alt}+n`,
  /** Copy session link */
  [`${Modifier.alt}+l`]: `${RuntimeModifier.alt}+l`,
  /** Open session info menu */
  [`${Modifier.alt}+i`]: `${RuntimeModifier.alt}+i`,

  /**
   * Navigate to previous session
   * The same key has `<` on the keyboard
   */
  comma: "comma",
  /**
   * Navigate to next session
   * The same key has `>` on the keyboard
   */
  period: "period",

  /** Global shortcut for current URL copy */
  [`${Modifier.alt}+c`]: `${RuntimeModifier.alt}+c`,

  /** Use for the main `create` or `new` or `add` button in a page */
  n: "n",
  /** Session filter search */
  f: "f",
} as const;

/* ------------------------------------------------------ */
/*                 Map runtime assertions                 */
/* ------------------------------------------------------ */
export function getInvalidShortcutKeys() {
  const keys = Object.keys(Shortcut);
  const invalidKeys = keys.filter((key) => key.includes("ctrl"));

  return invalidKeys;
}
(() => {
  const invalidKeys = getInvalidShortcutKeys();
  /**
   * Do not use `ctrl` modifier in shortcuts, instead use `command` modifier which translates to `meta ⌘` on Mac and `ctrl ⌃` on Windows
   */
  if (invalidKeys.length > 0) {
    throw new Error(
      `Invalid keys found in Shortcut enum: ${invalidKeys.join(", ")}`,
    );
  }
})();

export function getShortcutKeysWithIncorrectModifiersOrder() {
  const keys = Object.keys(Shortcut);
  const invalidKeys: string[] = [];
  for (const key of keys) {
    const modifiers = key
      .split("+")
      .filter((char) => Object.keys(Modifier).includes(char as Modifier));
    const isCorrectOrder = isCorrectOrderForModifiers(modifiers as Modifier[]);
    if (!isCorrectOrder) {
      invalidKeys.push(key);
    }
  }

  return invalidKeys;
}
(() => {
  const invalidKeys = getShortcutKeysWithIncorrectModifiersOrder();
  if (invalidKeys.length > 0) {
    throw new Error(
      `Incorrect order for modifiers in keys: ${invalidKeys.join(", ")}`,
    );
  }
})();

/* ------------------------------------------------------ */
/*                          Utils                         */
/* ------------------------------------------------------ */
export function isCorrectOrderForModifiers(keys: Modifier[]): boolean {
  let lastIndex = -1;
  for (const key of keys) {
    const index = OrderedModifiers.indexOf(key);

    if (index === -1) {
      throw new Error(`Invalid key: ${key}`);
    }

    if (index < lastIndex) {
      return false; // The order is incorrect
    }

    if (index > lastIndex) {
      lastIndex = index; // Update to the latest valid index
    }
  }

  return true; // The order is correct
}

export type ShortcutValueU = (typeof Shortcut)[keyof typeof Shortcut];
export type ShortcutU = keyof typeof Shortcut;
export function isShortcut(shortcut: string): shortcut is ShortcutU {
  return Object.keys(Shortcut).includes(shortcut as ShortcutU);
}

export function hotkeyToString(hotkey: Hotkey): ShortcutU | null {
  const character = hotkey.keys?.at(0);
  if (!character) return null;

  const modifiers: Modifier[] = [];
  if (hotkey.meta) modifiers.push(Modifier.command);
  if (hotkey.ctrl) modifiers.push(Modifier.command);
  if (hotkey.shift) modifiers.push(Modifier.shift);
  if (hotkey.alt) modifiers.push(Modifier.alt);

  if (modifiers.length > 1) {
    const isCorrectOrder = isCorrectOrderForModifiers(modifiers);
    if (!isCorrectOrder) return null;
  }

  const shortcut =
    modifiers.length > 0 ? `${modifiers.join("+")}+${character}` : character;
  if (!isShortcut(shortcut)) return null;

  return shortcut;
}
